import { Component } from '@angular/core'

@Component({
  selector: 'app-pending-acceptance',
  standalone: true,
  imports: [],
  templateUrl: './pending-acceptance.component.html',
  styleUrl: './pending-acceptance.component.css'
})
export class PendingAcceptanceComponent {}
