import { AsyncPipe, NgComponentOutlet } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { Observable, map } from 'rxjs'

import { signUpControls } from '@auth/data/signupField'
import { ControlInjector } from '@shared/features/forms/pipes/control-injector.pipe'
import { ControlResolver } from '@shared/features/forms/services/control-resolver/control-resolver.service'

import { Participant } from '@superlikers/models/participant'
import { Control } from '@shared/features/forms/models/forms.model'

import { SessionService } from '@auth/services/session.service'
import { ParticipantService } from '@participant/services/participant.service'

import { SubmitButtonComponent } from '@shared/components/submit-button/submit-button.component'

@Component({
  selector: 'app-complete-signup-page',
  standalone: true,
  imports: [ReactiveFormsModule, ControlInjector, AsyncPipe, RouterLink, NgComponentOutlet, SubmitButtonComponent],
  templateUrl: './complete-signup-page.component.html',
  styleUrl: './complete-signup-page.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteSignupPageComponent {
  router = inject(Router)

  sessionService = inject(SessionService)
  participantService = inject(ParticipantService)
  controlResolver = inject(ControlResolver)
  cdr = inject(ChangeDetectorRef)

  form = new FormGroup({})
  controls$!: Observable<Control[]>

  isLoading = false

  constructor() {
    this.controls$ = this.participantService.loadParticipant().pipe(
      map(data => {
        const participant = data.participation
        const controls = [...signUpControls]

        const controlsWithValues = controls.map(control => {
          const name = control.name as keyof Participant
          const value = participant[name]
          if (value) return { ...control, value }

          return control
        })

        return controlsWithValues
      })
    )
  }

  submit() {
    if (this.form.invalid) return

    this.isLoading = true

    const participant = { participation: this.form.value }

    this.sessionService.update(participant).subscribe(response => {
      if (response.success) {
        this.router.navigate(['/'])
      } else {
        this.isLoading = false
        this.showErrors(response.errors ?? {})
      }
    })
  }

  showErrors(errors: Record<string, string>) {
    this.controls$.subscribe(controls => {
      Object.entries(errors).forEach(entry => {
        const [label, error] = entry
        const controlData = controls.find(c => c.label === label)

        if (controlData) {
          const control = this.form.get(controlData.name)
          if (control) control.setErrors({ customError: error })
        }
      })
    })
  }
}
