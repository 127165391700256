<div class="h-screen grid grid-rows-[1fr,1fr] bg-theme-grey lg:grid-cols-2 lg:grid-rows-1">
  <div class="login-header relative rounded-b-3xl bg-black py-12 px-6 text-theme-white sm:px-14 lg:rounded-e-3xl lg:rounded-bl-none lg:pr-12 lg:pl-40 lg:py-12">
    <img
      class="hidden sm:block absolute lg:block -top-80 w-[120px] lg:w-36 lg:-top-28 left-4 lg:left-20 z-10 pointer-events-none"
      src="https://contenidospmm.s3.amazonaws.com/images/large-image.png"
      alt="" />

    <div class="mb-4 sm:mb-0 h-[338px] lg:h-[687px] flex flex-col justify-between">
      <div class="flex justify-between items-center">
        <img
          class="w-1/3 max-w-44 min-w-32"
          src="https://contenidospmm.s3.amazonaws.com/images/ppm_u-icon2.png"
          alt="PPM Logo" />
        <img
          class="w-1/3 max-w-44 min-w-32"
          src="https://contenidospmm.s3.amazonaws.com/images/abinbev-icon.png"
          alt="PPM Logo" />
      </div>
      <div class="flex flex-col gap-4 lg:gap-6">
        <p class="pt-14 max-w-60 lg:w-auto text-2xl lg:text-4xl font-bold leading-tight lg:max-w-96">
          Empieza tu recorrido y aprende con
          <span class="text-theme-yellow">los mejores expertos</span>
        </p>
        <p
          class="relative max-w-[450px] text-balance lg:w-auto bg-theme-yellow rounded-r-full text-black text-lg xl:text-xl lg:py-4 lg:pr-6 lg:pl-0 before:absolute before:w-[200px] before:h-full before:-left-[200px] before:top-0 before:bg-theme-yellow">
          Descubre el mundo de PPM University tiene para ti y sé parte de la herramienta de educación digital y consolida el conocimiento de
          <span class="font-bold">PPM Supply MAZ</span>.
        </p>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-4 items-stretch mx-auto mt-4 w-full max-w-2xl self-center bg-white rounded-xl p-6">
    <div>
      <h1 class="text-center text-xl font-bold">Hola Aprendiz, bienvenido</h1>
      <p class="text-center text-sm">Inicia tu sesión para continuar</p>
    </div>
    <form
      class="flex flex-col gap-4"
      (ngSubmit)="submit()"
      autocomplete="off"
      [formGroup]="form">
      @for (control of controls$ | async; track control.name) {
        <ng-container
          [ngComponentOutlet]="controlResolver.resolve(control, form) | async"
          [ngComponentOutletInjector]="control.name | controlInjector: control">
        </ng-container>
      }

      <app-submit-button
        class="self-center"
        type="submit"
        [isLoading]="isLoading">
        Actualizar
      </app-submit-button>
    </form>
  </div>
</div>
